import * as Msdyn365 from '@msdyn365-commerce/core';
import Cookies from 'universal-cookie';
export class GetDecryptionKeysInput implements Msdyn365.IActionInput {
    constructor() {}
    public getCacheKey = () => 'DecryptionKeysCache';
    public getCacheObjectType = () => 'DecryptionKeysCache';
    public dataCacheType = (): Msdyn365.CacheType => 'application';
}
export interface IActionGetDecryptionKeysData {
    EncryptionEnable: string | undefined;
    EncryptionAesKey: string | undefined;
    EncryptionAesIV: string | undefined;
    currentTime?: string;
    lastUpdateTime?: string;
}

const createInput = (args: Msdyn365.ICreateActionContext): Msdyn365.IActionInput => {
    return new GetDecryptionKeysInput();
};
async function Action(input: GetDecryptionKeysInput, ctx: Msdyn365.IActionContext): Promise<IActionGetDecryptionKeysData> {
    const encryptionEnable: Msdyn365.ISecretValue | undefined = await Msdyn365.msdyn365Commerce.secretManager?.getSecretValue(
        'encryptionEnable',
        ctx.requestContext.apiSettings.baseUrl
    );
    console.log({ encryptionEnable });
    const encryptionAesKey: Msdyn365.ISecretValue | undefined = await Msdyn365.msdyn365Commerce.secretManager?.getSecretValue(
        'encryptionAesKey',
        ctx.requestContext.apiSettings.baseUrl
    );
    const encryptionAesIV: Msdyn365.ISecretValue | undefined = await Msdyn365.msdyn365Commerce.secretManager?.getSecretValue(
        'encryptionAesIV',
        ctx.requestContext.apiSettings.baseUrl
    );

    if (encryptionEnable?.value === 'true') {
        const cookie = new Cookies();
        const currentTime = new Date();
        const storedCurrentTime = cookie.get('currentTime');
        if (!storedCurrentTime || isTimeToUpdate(currentTime, new Date(storedCurrentTime))) {
            await updateKeys(cookie, currentTime, encryptionAesKey, encryptionAesIV);
        }
        // Retrieve keys from cookies after update
        const EncryptionAesKey = cookie.get('EncryptionAesKey');
        const EncryptionAesIV = cookie.get('EncryptionAesIV');
        const updatedCurrentTime = cookie.get('currentTime');
        const lastUpdateTime = cookie.get('lastUpdateTime');
        return {
            EncryptionAesKey,
            EncryptionAesIV,
            EncryptionEnable: encryptionEnable?.value,
            currentTime: updatedCurrentTime,
            lastUpdateTime: lastUpdateTime
        };
    } else {
        // If encryption is not enabled, remove the cookies
        const cookie = new Cookies();
        cookie.remove('currentTime');
        cookie.remove('expireTime');
        cookie.remove(input.getCacheKey());
        return {
            EncryptionEnable: encryptionEnable?.value,
            EncryptionAesKey: '',
            EncryptionAesIV: '',
            currentTime: '',
            lastUpdateTime: ''
        };
    }
}

function isTimeToUpdate(currentTime: Date, storedTime: Date): boolean {
    const elapsedMinutes = (currentTime.getTime() - storedTime.getTime()) / (1000 * 60);
    return elapsedMinutes >= 2;
}
async function updateKeys(
    cookie: Cookies,
    currentTime: Date,
    encryptionAesKey: Msdyn365.ISecretValue | undefined,
    encryptionAesIV: Msdyn365.ISecretValue | undefined
): Promise<void> {
    const currentTimeString = currentTime.toISOString();
    // Set new values in cookies
    cookie.set('EncryptionAesKey', encryptionAesKey?.value);
    cookie.set('EncryptionAesIV', encryptionAesIV?.value);
    cookie.set('currentTime', currentTimeString);
    cookie.set('lastUpdateTime', currentTimeString);
}
export default Msdyn365.createObservableDataAction({
    action: <Msdyn365.IAction<IActionGetDecryptionKeysData>>Action,
    id: 'GetDecryptionKeys',
    input: createInput
});
